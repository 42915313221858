import * as React from "react"

const IconCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" role="img">
      <circle cx="20" cy="20" r="20" fill="#fff" />
      <path
        d="m17.8 27.6-7.2-6.1 2.4-2.8 4.6 3.9L28.2 12l2.6 2.6-13 13z"
        fill="var(--feature-highlight, #ffb90a)"
      />
    </svg>
  )
}

export default IconCheck
